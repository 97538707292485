
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    closeOnBackgroundClick: {
      type: Boolean,
      default: true,
    },
    src: {
      type: String,
    },
    thumbnail: {
      type: String,
    },
  },
  data(): any {
    return {
      expanded: false as boolean,
      closeButtonRef: null as any,
    };
  },

  methods: {
    closeImage(event: any): void {
      this.expanded = false;
      event.stopPropagation();
    },

    freezeVp(e: any) {
      e.preventDefault();
    },

    onExpandedImageClick(e: any) {
      e.stopPropagation();
      this.expanded = false;
    },

    getRenderedSize(
      cWidth: number,
      cHeight: number,
      oWidth: number,
      oHeight: number
    ) {
      const oRatio = oWidth > oHeight ? oWidth / oHeight : oHeight / oWidth;
      const width = oWidth >= oHeight ? oRatio * cHeight : cWidth;
      const height = oHeight > oWidth ? oRatio * cWidth : cHeight;
      const left = (this.cloned.clientWidth - width) / 2;
      const right = left + width;
      const top = (this.cloned.clientHeight - height) / 2;
      const bottom = top + height;
      return { left, top, right, bottom };
    },
  },

  watch: {
    expanded(status) {
      this.$nextTick(() => {
        if (status) {
          this.cloned = this.$el.cloneNode(true);
          this.closeButtonRef = this.cloned.querySelector(".close-button");
          this.closeButtonRef.addEventListener("click", this.closeImage);
          document.body.appendChild(this.cloned);
          document.body.style.overflow = "hidden";
          this.cloned.addEventListener("touchmove", this.freezeVp, false);
          if (this.closeOnBackgroundClick) {
            this.cloned.addEventListener("click", this.onExpandedImageClick);
          }
          setTimeout(() => {
            this.cloned.style.opacity = 1;
          }, 0);
        } else {
          this.cloned.style.opacity = 0;
          this.cloned.removeEventListener("touchmove", this.freezeVp, false);
          if (this.closeOnBackgroundClick) {
            this.cloned.removeEventListener("click", this.onExpandedImageClick);
          }
          setTimeout(() => {
            this.closeButtonRef.removeEventListener("click", this.closeImage);
            this.cloned.remove();
            this.cloned = null;
            this.closeButtonRef = null;
            document.body.style.overflow = "auto";
          }, 250);
        }
      });
    },
  },
})
export default class ExpandableImage extends Vue {
  closeOnBackgroundClick!: boolean;
  src!: string;
  thumbnail!: string;
}
