
import { Options, Vue } from "vue-class-component";
import ExpandableImage from "@/components/ExpandableImage.vue";

@Options({
  props: {
    image: String,
    text: String,
  },
  components: {
    ExpandableImage,
  },
})
export default class Portrait extends Vue {
  image!: string;
  text!: string;
}
