import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73b8f8d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "img-container" }
const _hoisted_2 = { class: "middle" }
const _hoisted_3 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExpandableImage = _resolveComponent("ExpandableImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ExpandableImage, {
      class: "image",
      src: require(`@/assets/images/${_ctx.image}`),
      thumbnail: require(`@/assets/thumbnails/${_ctx.image}`),
      alt: _ctx.text,
      title: _ctx.text
    }, null, 8, ["src", "thumbnail", "alt", "title"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.text), 1)
    ])
  ]))
}