
import { Options, Vue } from "vue-class-component";
import Portrait from "@/components/Portrait.vue";

@Options({
  props: {
    msg: String,
  },
  components: {
    Portrait,
  },
})
export default class HelloWorld extends Vue {
  msg!: string;
}
